<template>
  <div class="haishiDetails">
    <div class="content">
      <!-- 面包屑 -->
      <breadcrumb :list="this.$route" />
    </div>
    <div class="content">
      <div class="left">
        <!-- 指定分野 -->
        <div class="left_info">
          <img class="bck" src="./image/bck.png" alt="" />
          <div class="left_info_box">
            <img :src="user.avatar" alt="" />
            <div class="info">
              <div class="info1">
                <span>{{ user.jname|- }}</span>
              </div>
              <div class="info2">
                <p>{{ user.xname|- }}{{user.zhiwei?' · '+user.zhiwei:''}}</p>
                <div>
                  {{ user.endtime}}
                </div>
              </div>
              <div class="info3">
                <p v-for="(item,key) in user.major" :key="key">{{item}}</p>
              </div>
            </div>
          </div>
          <div class="title">
            推定分野
          </div>
          <div class="zbcharts" ref="charts"></div>
        </div>
        <!-- 进行中研究课题 -->
        <div class="left_kt">
          <div class="title">
            進行中の研究課題
          </div>
          <template v-if="tasking.length>0">
            <div
              class="kt_items"
              v-for="(item, key) in tasking"
              :key="key"
            >
              <div class="t1">{{item.title}}</div>
              <div class="sub">
                <p>推定分野</p>
                <div>
                  <span v-for="item in item.major" :key="item">{{item}}</span>
                </div>
              </div>
              <div class="sub">
                <p>研究期間</p>
                <div>
                  {{item.end_time}}
                </div>
              </div>
              <div class="sub">
                <p>当時の所属</p>
                <div>
                  {{ user.xname|- }}{{user.zhiwei?' · '+user.zhiwei:''}}
                </div>
              </div>
            </div>
          </template>
          <div v-else class="pj_null">
             <img  src="@/assets/null2.png" alt="">
             <span>暂无数据</span>
          </div>
        </div>
        <!-- 结束研究课题 -->
        <div class="left_kt">
          <div class="title">
            終了した研究課題
          </div>
          <template v-if="task.length>0">
            <div
              class="kt_items"
              v-for="(item, key) in task"
              :key="key"
            >
              <div class="t1">{{item.title}}</div>
              <div class="sub">
                <p>推定分野</p>
                <div>
                  <span v-for="item in item.major" :key="item">{{item}}</span>
                </div>
              </div>
              <div class="sub">
                <p>研究期間</p>
                <div>
                  {{item.end_time}}
                </div>
              </div>
              <div class="sub">
                <p>当時の所属</p>
                <div>
                  {{ user.xname|- }}{{user.zhiwei?' · '+user.zhiwei:''}}
                </div>
              </div>
            </div>
          </template>
          <div v-else class="pj_null">
             <img  src="@/assets/null2.png" alt="">
             <span>暂无数据</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">
          推荐教授
          <span @click="getTuijian"
            ><i class="xhgrefresh iconfont"></i>换一批</span
          >
        </div>
        <div class="tuijian_items" v-for="(item, key) in tuijian" :key="key" @click="goDetaile(item.id)">
          <img :src="item.avatar" alt="" />
          <div class="tuijian_items_box">
            <div class="t1">
              <p :title="item.jname">{{ item.jname }} </p>
            </div>
            <div class="t2">
              <p :title="item.xname +' · '+item.zhiwei">{{item.xname +' · '+item.zhiwei}} </p>
            </div>
            <div class="t3">
              {{item.endtime|-}}
            </div>
            <div class="t2">
              <p >{{item.major[0]}} </p>
            </div>
            <div class="t2">
              <p :title="item.major[1]">{{item.major[1]}} </p>
            </div>
            <!-- <div class="t3"><p :title="item.school.zwmc">{{ item.school.zwmc }}</p></div> -->
          </div>
        </div>
      </div>
    </div>
    <share v-if="flag" @share="share" :share_url="share_url" />
  </div>
</template>

<script>
import breadcrumb from "@/components/el-breadcrumb/el-breadcrumb.vue";
import { getDetails,getRecommend } from "@/api/teach";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import share from '@/views/haishi/components/share';

export default {
  components:{share,breadcrumb},
  data() {
    return {
      user:{},//教授信息
      tasking:{},//进行中的项目
      task:{},//结束的项目
      chart:null,
      share_url:"",//分享url
      flag:false,//分享弹窗
      tuijian: [], //推荐海师
    };
  },
  created(){
    //判断移动还是pc
    // this.browserRedirect()
  },
  async mounted() {
    this.getTuijian();
    this.$nextTick(()=>{
      this.getDtl();
    })
  },
  methods: {
    //判断移动还是pc
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        // window.location.href = "移动端网站地址";
        this.$router.push(`/MhsDetail?id=${this.$route.query.id}`)
      } else {
        // window.location = "PC端网站地址";
        // this.$router.push(`/haishi/haishiDetails?id=${this.$route.query.id}`);
      }
    },
    //推荐教授
    getTuijian() {
      getRecommend({
        limit: 6,
        id: this.$route.query.id,
      }).then((res) => {
        this.tuijian = res.data;
      });
    },
    //获取教授详情信息
    async getDtl() {
      await getDetails({
        tid:this.$route.query.id
      }).then((res) => {
        this.user = res.data.user;
        this.tasking = res.data.tasking;//进行中的项目
        this.task = res.data.task;//结束的项目
        this.charts(res.data.major);
      });
    },
    //图表
    charts(data) {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create(this.$refs.charts, am4charts.PieChart);
      this.chart = chart
      chart.data = data;
      chart.padding(0, 20, 0, 20);
      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "ratio";
      pieSeries.dataFields.category = "name";
      pieSeries.slices.template.stroke = am4core.color("#fff");
      // pieSeries.slices.template.strokeOpacity = 1;
      // pieSeries.labels.template.text = "[#666666]{name}{value}次[/]";
      pieSeries.labels.template.text = `[#333333 text-align:center] {value}%[/] \n [#999999 text-align:center]{name}[/]`;
      pieSeries.labels.template.maxWidth = 120;
      pieSeries.labels.template.fontSize = 15;
      pieSeries.labels.template.truncate = true
      pieSeries.slices.template.tooltipText = "[#ffffff text-align:center]{category}: {value.value}%[/]";
      pieSeries.legendSettings.valueText = "{valueY.close}";
      //添加白线分割
      pieSeries.slices.template.stroke = am4core.color("#FFFFFF");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      //旋转进场动画
      pieSeries.hiddenState.properties.endAngle = -90;
      // chart.hiddenState.properties.radius = am4core.percent(0);
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.paddingRight = 0
      chart.legend.labels.template.fill = am4core.color("#62472C");
      chart.legend.valueLabels.template.fill = am4core.color("#62472C");
      var markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 20;
      markerTemplate.height = 20;
      pieSeries.colors.list = [
        am4core.color("#DDA377"),
        am4core.color("#D4C4A2"),
        am4core.color("#B0C59C"),
      ];
    },
    //跳转当前页
    goDetaile(id){
      let routeData = this.$router.resolve({
        path: "/teachdetai",
        query: {id:id}
      });
      window.open(routeData.href, '_blank');
    }
  },
  beforeDestroy(){
    // console.log('销毁')
    // this.chart.dispose()
  }
};
</script>

<style scoped lang="scss">
.haishiDetails {
  background: #f5f5f5;
  padding-bottom: 40px;
  font-family: "japanFont";
  .content {
    width: 1200px;
    margin: auto;
    display: flex;
    //公共标题样式
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #60452F;
      padding-top: 10px;
      position: relative;
      display: flex;
      justify-content: space-between;
      z-index: 2;
      padding-left: 30px;
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        background: linear-gradient(90deg, #E7D698 0%, #CAAD5F 100%);
        position: absolute;
        border-radius: 50%;
        left: 0px;
        z-index: -1;
      }
    }
    //左大块
    .left {
      width: 890px;
      margin-right: 10px;
      .left_info {
        width: 890px;
        height: 635px;
        background: #ffffff;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        padding: 0 20px;
        box-sizing: border-box;
        .bck {
          width: 100%;
          height: 112px;
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0px;
          left: 0px;
        }
        .left_info_box {
          height: 110px;
          margin-top: 100px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          margin-bottom: 38px;
          img {
            width: 110px;
            height: 110px;
            border-radius: 50%;
            margin-right: 20px;
            border: 4px solid white;
            box-sizing: border-box;
            background: white;
            object-fit: cover;
          }
          .info {
            flex: 1;
            padding-top: 20px;
            > div {
              display: flex;
              align-items: center;
            }
            .info1 {
              span:nth-of-type(1) {
                font-size: 18px;
                font-weight: 500;
                color: #231716;
                margin-right: 10px;
              }
              span:nth-of-type(2) {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                margin-right: 10px;
              }
              span:nth-of-type(3) {
                display: inline-block;
                background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
                border-radius: 8px 2px 8px 2px;
                font-size: 14px;
                font-weight: 500;
                color: #935d14;
                padding: 2px 6px;
              }
            }
            .info2 {
              margin-top: 10px;
              p {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                margin-right: 15px;
              }
              div {
                font-size: 16px;
                font-weight: 400;
                color: #60452F;
              }
            }
            .info3 {
              margin-top: 10px;
              p {
                font-size: 16px;
                font-weight: 400;
                color: #3A3A3A;
                margin-right: 20px;
              }
              span {
                font-size: 16px;
                color: #3A3A3A;
              }
            }
          }
        }
        .zbcharts {
          width: 100%;
          height: 270px;
          position: absolute;
          bottom: 40px;
          left: 0px;
          &::after {
            content: "";
            width: 60px;
            height: 22px;
            background: white;
            position: absolute;
            bottom: 0px;
            left: 0px;
          }
        }
      }
      .left_kt {
        margin-top: 10px;
        width: 890px;
        background: #ffffff;
        border-radius: 8px;
        padding: 20px 20px;
        .title {
          padding: 0px 20px;
          margin-bottom: 10px;
          padding-left: 30px;
          &::before {
            left: 0px;
          }
        }
        .kt_items {
          width: 100%;
          padding: 15px 0px;
          border-bottom: 1px solid #EEEEEE;
          &:hover {
            background: #fafafa;
          }
          .t1{
            color: #3A3A3A;
            font-size: 18px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .sub{
            display: flex;
            margin-top: 10px;
            font-size: 16px;
            flex-shrink: 0;
            p{
              width: 100px;
              color: #5B7084;
              flex-shrink: 0;
            }
            div{
              color: #3A3A3A;
              flex: 1;
              flex-shrink: 0;
              white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
              span{
                margin-right: 15px;
              }
            }
          }
        }
        .pj_null{
          height: 500px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          span{
            font-size: 16px;
            font-weight: 400;
            color: #B1C2D9;
          }
        }
      }
    }
    //右大块
    .right {
      width: 300px;
      height: 916px;
      background: #ffffff;
      border-radius: 8px;
      padding: 0px 20px;
      padding-top: 10px;
      box-sizing: border-box;
      .title {
        padding-top: 20px;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
          i {
            margin-right: 10px;
            color: #999999;
          }
          &:hover {
            i {
              color: #1890ff;
            }
          }
        }
      }
      .tuijian_items {
        height: 143px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        > img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
          margin-top: 20px;
        }
        > .tuijian_items_box {
          width: 200px;
          height: 100%;
          border-bottom: 1px solid #ededed;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .t1 {
            font-size: 14px;
            font-weight: 400;
            color: #231716;
            display: flex;
            align-items: center;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            p{
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }
          }
          .t2 {
            display: flex;
            align-items: center;
            margin-top: 7px;
            font-size: 13px;
            font-weight: 400;
            color: #333333;
            >p{
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .t3{
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            margin-top: 7px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &:last-child {
          > div {
            border: none;
          }
        }
      }
    }
  }
}
</style>
